import { createStyles, Group, Text, Title } from '@mantine/core';
import IntegrationCard from 'components/IntegrationCard/IntegrationCard';
import GreenhouseWordmark from 'assets/img/greenhouse_wordmark.svg';
import LeverWordmark from 'assets/img/lever_wordmark.png';
import OktaWordmark from 'assets/img/okta_wordmark.png';
import ICIMSWordmark from 'assets/img/icims_wordmark.png';
import SapWordmark from 'assets/img/sap_wordmark2.jpg';
import { useGetCompanyQuery } from 'app/services/rolebot';
import useAppSelector from "../../hooks/useAppSelector";

const DESCRIPTIONS = {
  greenhouse: 'Easily launch roles, search and export candidates to your ‘Candidates + Prospects’ database.',
  lever: 'Easily launch roles, search and export candidates to your Lever candidate database.',
  okta: "Rolebot's Okta integration enables customers to log into Rolebot using Okta as a single sign-on provider.",
  sap: "Easily launch roles, search and export candidates to your candidate database.",
  icims: "Easily launch roles, search and export candidates to your candidate database.",
};

const useStyles = createStyles((theme) => ({
  integrationsContainer: {
    marginBottom: 30,
    justifyContent: 'center',
    [theme.fn.largerThan('xs')]: {
      justifyContent: 'flex-start',
    },
  },
}));

const IntegrationsDashboard = () => {
  const { classes } = useStyles();
  const { data } = useGetCompanyQuery();
  const requiresLeverOauth = useAppSelector((state) => state.app.requiresLeverOauth);

  return (
    <div>
      <Title mb={30} color={'#242424'} order={2}>
        Integrations
      </Title>
      <Text mb={30} color={'#838485'}>
        Manage and customize your Apps
      </Text>
      <Group spacing={20} className={classes.integrationsContainer}>
        <IntegrationCard
          asset={GreenhouseWordmark}
          to={'greenhouse'}
          title={'Greenhouse'}
          description={DESCRIPTIONS.greenhouse}
          active={Boolean(data?.greenhouse)}
        />
        <IntegrationCard
          asset={LeverWordmark}
          to={'lever'}
          title={'Lever'}
          description={DESCRIPTIONS.lever}
          active={Boolean(data?.lever)}
          requiresOauth={requiresLeverOauth}
        />
        <IntegrationCard
          asset={SapWordmark}
          to={'sap'}
          title={'SAP SuccessFactors'}
          description={DESCRIPTIONS.sap}
          active={Boolean(data?.sap)}
        />
        <IntegrationCard
          asset={ICIMSWordmark}
          to={'icims'}
          title={'ICIMS'}
          description={DESCRIPTIONS.icims}
          active={Boolean(data?.icims)}
        />
        <IntegrationCard
          asset={OktaWordmark}
          to={'okta'}
          title={'Okta'}
          description={DESCRIPTIONS.okta}
          active={Boolean(data?.okta)}
        />
      </Group>
    </div>
  );
};

export default IntegrationsDashboard;
