import React, { useMemo } from 'react';
import { Box, createStyles, Group, Stack, Title, UnstyledButton } from '@mantine/core';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faCreditCard, faPuzzlePiece, faSlidersSimple, faShieldCheck } from '@fortawesome/pro-light-svg-icons';
import { useGetCompanyQuery } from 'app/services/rolebot';

const useStyles = createStyles((theme) => ({
  settingsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.fn.largerThan('md')]: {
      flexDirection: 'row',
      height: '100%',
    },
  },
  settingsPanel: {
    height: '100%',
    position: 'relative',
    '&:after': {
      boxShadow: '1px 0px 3px rgba(88, 89, 91, 0.15)',
      content: '""',
      display: 'block',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: 1,
      backgroundColor: '#58595B26',
    },
  },
  button: {
    minWidth: 'fit-content',
    backgroundColor: 'transparent',
    borderRadius: 10,
    paddingInline: 10,
    paddingBlock: 5,
    ':hover': {
      backgroundColor: '#EEEEEE',
      transition: 'background-color 0.2s ease-in-out',
    },
    [theme.fn.largerThan('md')]: {
      minWidth: 150,
    },
  },
  activeButton: {
    backgroundColor: '#8241FD2E',
    ':hover': {
      backgroundColor: '#8241FD2E',
    },
  },
  settingsContent: {
    marginTop: 38,
    marginLeft: 30,
    marginRight: 30,
    [theme.fn.largerThan('md')]: {
      width: '100%',
    },
  },
}));
const Settings = () => {
  const { classes, cx } = useStyles();
  const location = useLocation();
  
  const { data } = useGetCompanyQuery();

  const isAdmin = useMemo(() => {
    return Boolean(data?.customer_user?.is_admin);
  }, [data]);

  return (
    <div className={classes.settingsContainer}>
      <Box className={classes.settingsPanel} pl={30} pr={30}>
        <Title order={3} mb={42} color={'#242424'} mt={42}>
          Settings
        </Title>
        <Stack spacing={2}>
          {isAdmin && (
            <UnstyledButton
            component={NavLink}
            className={cx(classes.button, { [classes.activeButton]: location.pathname === '/settings/users' })}
            to={'users'}
          >
            <Group noWrap spacing={10}>
              <FontAwesomeIcon
                icon={faUserCircle}
                width={18}
                height={18}
                style={{ paddingBottom: '2px', color: location.pathname === '/settings/users' ? "#24154F" : "#242424" }}
              />
              <span style={{color: location.pathname === '/settings/users' ? "#24154F" : "#242424"}}>Users</span>
            </Group>
          </UnstyledButton>
          )}
          {isAdmin && (
            <UnstyledButton
            component={NavLink}
            className={cx(classes.button, { [classes.activeButton]: location.pathname === '/settings/billing' })}
            to={'billing'}
          >
            <Group noWrap spacing={10}>
              <FontAwesomeIcon
                icon={faCreditCard}
                width={17}
                height={17}
                style={{ paddingBottom: '2px', color: location.pathname === '/settings/billing' ? "#24154F" : "#242424" }}
              />
              <span style={{color: location.pathname === '/settings/billing' ? "#24154F" : "#242424"}}>Billing</span>
            </Group>
          </UnstyledButton>
          )}
          {isAdmin && (
            <UnstyledButton
            component={NavLink}
            className={cx(classes.button, { [classes.activeButton]: location.pathname === '/settings/integrations' })}
            to={'integrations'}
          >
            <Group noWrap spacing={10}>
              <FontAwesomeIcon
                icon={faPuzzlePiece}
                width={18}
                height={18}
                style={{ paddingBottom: '2px', color: location.pathname === '/settings/integrations' ? "#24154F" : "#242424" }}
              />
              <span style={{color: location.pathname === '/settings/integrations' ? "#24154F" : "#242424"}}>Integrations</span>
            </Group>
          </UnstyledButton>
          )}
          <UnstyledButton
            component={NavLink}
            className={cx(classes.button, { [classes.activeButton]: location.pathname === '/settings/customizations' })}
            to={'customizations'}
          >
            <Group noWrap spacing={10} >
              <FontAwesomeIcon
                icon={faSlidersSimple}
                width={18}
                height={18}
                style={{ paddingBottom: '2px', color: location.pathname === '/settings/customizations' ? "#24154F" : "#242424" }}
              />
              <span style={{color: location.pathname === '/settings/customizations' ? "#24154F" : "#242424"}}>Customizations</span>
            </Group>
          </UnstyledButton>
          {isAdmin && (
            <UnstyledButton
            component={NavLink}
            className={cx(classes.button, { [classes.activeButton]: location.pathname === '/settings/security' })}
            to={'security'}
          >
            <Group noWrap spacing={10}>
              <FontAwesomeIcon
                icon={faShieldCheck}
                width={18}
                height={18}
                style={{ paddingBottom: '2px', color: location.pathname === '/settings/security' ? "#24154F" : "#242424" }}
              />
              <span style={{color: location.pathname === '/settings/security' ? "#24154F" : "#242424"}}>Security</span>
            </Group>
          </UnstyledButton>
          )}
        </Stack>
      </Box>
      <Box className={classes.settingsContent}>
        <Outlet />
      </Box>
    </div>
  );
};

export default Settings;
