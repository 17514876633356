import { createStyles, UnstyledButton } from "@mantine/core";

const MenuItem = ({ children, onClick, isActive=false }: { children: React.ReactNode; onClick: () => void, isActive?: boolean }) => {

    const useStyles = createStyles((theme) => ({
        menuItem: {
            display: 'flex',
            alignItems: 'center',
            padding: `${theme.spacing.sm}px ${theme.spacing.sm}px`,
            gap: theme.spacing.xs,
            width: '100%',
            '&:hover': {
                backgroundColor: theme.colors.gray[0],
            },
            backgroundColor: isActive ? theme.colors.gray[1] : 'transparent',
        },
    }));

    const { classes } = useStyles();
  
    return (
    <UnstyledButton
      className={classes.menuItem}
      onClick={onClick}
    >
      {children}
    </UnstyledButton>
  )};

export default MenuItem;