import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Title, Text, Group } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { useNotifications } from "@mantine/notifications";
import { useGetCompanyQuery, usePostMfaStatusMutation } from "app/services/rolebot";
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { useEffect } from "react";


const Accounts = () => {
  const notifications = useNotifications()
  const modals = useModals()
  const { data: companyData } = useGetCompanyQuery();
  const [postMfaStatus, { isLoading, isSuccess, isError, error }] = usePostMfaStatusMutation();

  const handleEnableMfa = () => {
    postMfaStatus({
        mfa_enabled: true,
        customer_id: companyData!.id
    })
  }

  useEffect(() => {
    if (isSuccess) {
      modals.openContextModal('enableMFA', {
        title: '',
        innerProps: {},
        closeOnClickOutside: false,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      notifications.showNotification({
        title: 'Oops!',
        color: 'yellow',
        message: 'Could not enable MFA',
        autoClose: 3000,
      });
    }
  }, [isError]);

  const openDeactivateMFAModal = () => {
    modals.openContextModal('disableMFA', {
      title: '',
      innerProps: {
        customer_id: companyData!.id
      },
      closeOnClickOutside: false,
    });
  };

  return (
    <>
      <Stack mt={20} style={{ maxWidth: '600px' }}>
        <Title order={4} style={{color: '#242424'}} mb={4}>
          Two-factor authentication
        </Title>
        <Text color={'#838485'}>
            Two-factor authentication adds an extra layer of protection to your Rolebot account, requiring users to
            provide a second form of authentication alongside their password during login.
        </Text>
        <Text color={'#838485'}>
            Once enabled, users will need to enter a unique token sent via email to complete the login process,
            ensuring only authorized users gain access to your account.
        </Text>
      </Stack>
      {companyData && !companyData.mfa_enabled && (<Group position={'left'} mt={40}>
        <RolebotButton type={'primary'} onClick={handleEnableMfa} loading={isLoading}>
          Enable Two-factor authentication
        </RolebotButton>
      </Group>) }
      {companyData && companyData.mfa_enabled && (<Group position={'left'} mt={30}>
        <Stack spacing={40}>
            <Text style={{fontWeight: 400, color: '#7039ED'}}>
                <FontAwesomeIcon
                    icon={faCheck}
                    width={18}
                    height={18}
                    style={{paddingRight: '10px', color: '#7039ED' }}
                />
                Two-factor authentication is active
            </Text>
            <div>
                <RolebotButton type={'stand'} onClick={openDeactivateMFAModal}>
                    Deactivate
                </RolebotButton>
            </div>
            
        </Stack>
        
      </Group>) }
    </>
  );
}

export default Accounts

