import { Group, Stack, Tabs, Text, Title, Pagination } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { useDeleteCompanyLocationMutation, useGetCompanyLocationsQuery, useGetCompanyQuery } from 'app/services/rolebot';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import OfficeRow from 'components/OfficeRow/OfficeRow';
import { useEffect, useMemo, useState } from 'react';

const Customizations = () => {

  const [activePage, setPage] = useState(1);

  const {refetch, data } = useGetCompanyLocationsQuery({
    per_page: 15,
    page: activePage
  }, { refetchOnMountOrArgChange: true })

  const { data: companyData } = useGetCompanyQuery();

  const isAdmin = useMemo(() => {
    return Boolean(companyData?.customer_user?.is_admin);
  }, [companyData]);

  const modals = useModals();

  const openNewOfficeModal = () => {
    modals.openContextModal('newOffice', {
      title: '',
      innerProps: {},
      closeOnClickOutside: false,
      onClose() {
        refetch()
      },
    });
  };
  
  const [deleteCompanyLocation, { isSuccess }] = useDeleteCompanyLocationMutation();


  const handleDeleteLocation = (id:number) => {
    deleteCompanyLocation({location_id: id})
  }

  useEffect(() => {
    if(isSuccess) refetch()
  }, [isSuccess])

  return (
    <div>
      <Stack mb={30}>
        <Title order={2} style={{color:'#242424'}}>
          Customizations
        </Title>
      </Stack>

      <Tabs variant={'unstyled'}>
        <Tabs.Tab label="Offices">
          <Stack mt={20} style={{maxWidth: '600px'}}>
            <Title order={4} color={'#242424'}>
              Offices
            </Title>
            <Text color={'#838485'}>
            Create office locations to better organize your company's roles. If your company has multiple locations, this will help your team identify and differentiate roles.
            </Text>
            <Text mb={10} color={'#838485'}>You can tie a role to one or multiple office locations.</Text>
          </Stack>
          <Group position={'left'} mt={30}>
            <RolebotButton type={'neutral'} onClick={openNewOfficeModal}>
              + New Office
            </RolebotButton>
          </Group>
          <div style={{width: '100%', marginTop: '30px', maxWidth: '500px'}}>
            {data?.data && data?.data.map( (office: any, index: number) =>
              (
                <div key={`${office.id}-${index}`}>
                  <OfficeRow id={office.id} name={office.location_name} onDelete={handleDeleteLocation} isAdmin={isAdmin}/>
                </div>
              )
            )}
            
            {data && data?.last_page !== 1 && (
              <div style={{display: 'flex', width: '100%', justifyContent:'center', marginBottom: '40px'}}>
                <Pagination style={{marginLeft: 'auto', marginRight: 'auto'}} mt={20} onChange={setPage} total={data?.last_page ?? 1} />
              </div>
            )}
          </div>
            
        </Tabs.Tab>
      </Tabs>
    </div>
  );
};

export default Customizations;
