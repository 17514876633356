import { useEffect, useMemo, useRef, useState } from 'react';
import { Center, createStyles, Divider, Group, MediaQuery, Stack, Text } from '@mantine/core';
import { useGetCompanyQuery, useGetUserQuery, useSignOutMutation } from 'app/services/rolebot';
import { useLocation, useNavigate } from 'react-router-dom';
import useOpenModal from 'hooks/useOpenModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from './MenuItem';
import { faUser, faFileLines, faGear, faCircleQuestion, faArrowRightFromBracket } from '@fortawesome/pro-light-svg-icons';
import UserAvatar from './UserAvatar';

interface UserMenuProps {
  onNavigate?: () => void;
}


const useStyles = createStyles((theme) => ({
  menuWrapper: {
    position: 'relative',
    display: 'flex',
    padding: 32,
    justifyContent: 'center',
    [theme.fn.largerThan('md')]: {
      width: '100%',
      padding: 0,
      marginTop: 40,
    },
  },

  menuTrigger: {
    [`@media (max-width: 992px)`]: {
      display: 'none',
    }
  },

  menuContent: {
    [`@media (min-width: 993px)`]: {
      display: 'none',
      '&[data-expanded="true"]': {
        display: 'block',
        position: 'absolute',
        left: 65,
        bottom: 0,
        backgroundColor: theme.white,
        border: `1px solid ${theme.colors.gray[2]}`,
        borderRadius: theme.radius.sm,
        boxShadow: theme.shadows.md,
        width: 260,
        zIndex: 1000,
      }
    },
    
    [`@media (max-width: 992px)`]: {
      width: '100%',
      display: 'block',
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
    }
  },

  avatar: {
    borderRadius: 100,
    width: 47,
    height: 47,
  },
  userInfoContainer: {
    [`@media (max-width: 992px)`]: {
      paddingLeft: '0px',
    }
  },
  placeholder: {
    fontSize: theme.fontSizes.xl,
  },
  name: {
    fontFamily: 'Roboto',
    fontSize: theme.fontSizes.md,
    lineHeight: 1.4,
  },
  company: {
    fontSize: theme.fontSizes.sm,
    color: '#838485',
  },

  innerPlaceholder: {
    fontSize: theme.fontSizes.lg,
  },
  icon: {
    paddingBottom: '2px'
  },
  contactIcon: {
    paddingBottom: '4px'
  },
  menuItemText: {
    fontSize: 20,
    [theme.fn.largerThan('md')]: {
      fontSize: 16,
    },
  }
}));

const UserMenu = ({ onNavigate }: UserMenuProps) => {

  const { classes } = useStyles();
  const navigate = useNavigate();

  const locationPath = useLocation().pathname;

  const user = useGetUserQuery();
  const userName = user?.data?.user?.name || '';

  const { data } = useGetCompanyQuery();
  const isAdmin = useMemo(() => {
    return Boolean(data?.customer_user?.is_admin);
  }, [data]);

  const [signOut] = useSignOutMutation();
  const handleSignOut = async () => await signOut();

  const { contactSupport } = useOpenModal();
  const openContactSupportModal = () => contactSupport();

  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);


  // Close the menu when clicking outside and when the user navigates to a different page
  useEffect(() => { 
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    setIsOpen(false); // Close the menu when the user navigates to a different page

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [locationPath ]);

  const handleNavigation = (callback: () => void) => {
    callback();
    onNavigate?.();
  };

  const isPathActive = (path: string) => {
      return locationPath.startsWith(path);
  };


  return (
    <div className={classes.menuWrapper} ref={menuRef}>
      <div className={classes.menuTrigger}>
        <Center 
          sx={{ cursor: 'pointer' }}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          <UserAvatar />
        </Center>
      </div>
      <div className={classes.menuContent} data-expanded={isOpen ? "true" : undefined}>
        <div>
          <Group spacing="xs" noWrap p="md" className={classes.userInfoContainer}>
            <UserAvatar />
            <Stack spacing={0}>
              <Text className={classes.name} lineClamp={1}>
                {userName}
              </Text>
              <Text className={classes.company} lineClamp={1}>
                {data?.name}
              </Text>
            </Stack>
          </Group>

          <Divider />

          <Stack spacing={0}>
            <MenuItem onClick={() => handleNavigation(() => navigate('/profile'))} isActive={isPathActive('/profile')}>
              <FontAwesomeIcon icon={faUser} width={20} className={classes.icon}/>
              <Text className={classes.menuItemText}>My Profile</Text>
            </MenuItem>
            
            {isAdmin && (
              <MenuItem onClick={() => handleNavigation(() => navigate('/role-stats'))} isActive={isPathActive('/role-stats')}>
                <FontAwesomeIcon icon={faFileLines} width={20} className={classes.icon} />
                <Text className={classes.menuItemText}>Role Stats</Text>
              </MenuItem>
            )}
            {/* Hide settings menu on mobile */}
            <MediaQuery query="(max-width: 576px)" styles={{ display: 'none' }}>
              <div>
                <MenuItem onClick={() => handleNavigation(() => navigate(isAdmin ? '/settings/users' : '/settings/customizations'))} isActive={isPathActive('/settings')}>
                  <FontAwesomeIcon icon={faGear} width={20} className={classes.icon} />
                  <Text className={classes.menuItemText}>Settings</Text>
              </MenuItem>
              </div>
            </MediaQuery>
            
            <Divider />
            
            <MenuItem onClick={openContactSupportModal}>
              <FontAwesomeIcon icon={faCircleQuestion} width={20} className={classes.contactIcon}/>
              <Text className={classes.menuItemText}>Contact Support</Text>
            </MenuItem>
            
            <Divider />
            
            <MenuItem onClick={handleSignOut}>
              <FontAwesomeIcon icon={faArrowRightFromBracket} width={20} className={classes.icon}/>
              <Text className={classes.menuItemText}>Log Out</Text>
            </MenuItem>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
