import { Tabs, TabsProps } from '@mantine/core';
import React from 'react';

interface RolebotTabsProps extends TabsProps {
  origin?: string;
}

const RolebotTabs = React.forwardRef((props: RolebotTabsProps, ref: any) => {
  const { origin, ...restProps } = props;

  return (
    <Tabs
      ref={ref as any}
      {...restProps}
      variant={'unstyled'}
      styles={(theme) => ({
        root: {
          height: '100%',
          minHeight: '200px',
          [theme.fn.largerThan('sm')]: {
            display: 'flex',
            flexDirection: 'column',
          },
        },
        tabControl: {
          color: '#46209E66',
          fontSize: 22,
          fontWeight: 700,
          borderBottom: '2px solid transparent',
        },
        tabActive: {
          color: '#522BB5',
          borderBottomColor: '#522BB5',
          span: {
            color: '#838485',
          },
        },
        tabsListWrapper: {
          borderBottom: '1px solid #DFE1E1',
          boxShadow: '0px 2px 3px -3px rgba(88,89,91,0.76)',
          // marginTop: 10,
          [theme.fn.largerThan('md')]: {
            marginTop: 0,
          },
        },
        tabsList: {
          flexWrap: 'nowrap',
          [theme.fn.largerThan('sm')]: {
            display: 'flex',
          },
          [theme.fn.largerThan('md')]: {
            overflowX: 'hidden',
          },
          marginBottom: -1,
          overflowX: 'scroll',
          '::-webkit-scrollbar': {
            height: '0px',
            width: '0px',
            background: 'transparent',
          },
          ':-webkit-scrollbar': {
            display: 'none',
          },
        },
        tabLabel: {
          whiteSpace: 'nowrap',
          fontSize: origin === 'role-info' ? '16px' : '19px',
          ':hover': {
            transition: 'color 0.2s ease-in-out',
            color: '#522BB5',
            span: {
              color: '#838485',
            },
          },
        },
        body: {
          height: '100%',
          paddingTop: 0,
          paddingBottom: '1px'
        },
      })}
    />
  );
});

export default RolebotTabs;
