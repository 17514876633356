import { AppShell, Burger, createStyles, Header, Image, MediaQuery, Navbar,  useMantineTheme } from '@mantine/core';
import Logo from 'assets/img/logos/rolebot-logo.png';
import { NavLink } from 'react-router-dom';
import { navbarRoutes } from 'router/routes';
import UserMenu from 'components/UserMenu/UserMenu';
import useShowModals from 'hooks/useShowModals';
import { useState } from 'react';
import NavbarLink from './NavbarLink';

const useStyles = createStyles((theme) => ({

  homeLink: {
    display: 'none',
    [theme.fn.largerThan('md')]: {
      display: 'flex',
    },
  },

  inner: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  desktopNavbar: {
    borderRight: 'none',
    width: 68,
    backgroundColor: '#24154F',
    height: '100%',
    top: 0,
    [theme.fn.smallerThan('md')]: {
      height: '-webkit-fill-available',
      maxHeight: '95dvh',
      top: 'var(--mantine-header-height);',
      backgroundColor: '#fff',
      color: '#000',
      width: '100%',
      justifyContent: 'space-between',
      overflow: 'scroll',
    }
  },

  navbar: {
    zIndex: 100,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
  },

  mobileNav: {
    backgroundColor: '#1E1650',
    color: 'white',
    display: 'none',
    [theme.fn.smallerThan('md')]: {
      display: 'block'
    },
  },

  title: {
    fontSize: 22,
    lineHeight: 1.3,
    wordBreak: 'keep-all',
    paddingInline: 10,
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    textAlign: 'center',
    [theme.fn.largerThan('md')]: {
      paddingInline: 20,
      fontSize: 22,
    },
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 40,
    padding: 40,
    [theme.fn.largerThan('md')]: {
      padding: 0,
      gap: 0,
    },
  },

  navbarItem: {
    display: 'flex',
    width: '100%',
    [theme.fn.largerThan('md')]: {
      marginTop: 40,
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  userMenuContainer: {
    [theme.fn.largerThan('md')]: {
      marginTop: 'auto',
      marginBottom: 16,
    },
  },

  noPadding: {
    paddingLeft: 16,
    paddingRight: 16,
    [theme.fn.smallerThan('md')]: {
      paddingInline: 0,
    },
  },

  root: {
    top: 0,
  }
}));


const SiteWrapper = ({ children }: any) => {

  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();
  
  useShowModals();

  return (
    <AppShell
      fixed
      styles={(theme) => ({
        root: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
        body: {
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          marginTop: 'var(--mantine-header-height)',
          justifyContent: 'space-between',
          [theme.fn.largerThan('md')]: {
            marginTop: 0,
            flexDirection: 'row',
            justifyContent: 'flex-start',
          },
        },
        main: {
          flex: 1,
          paddingTop: 0,
          paddingLeft: 0,
          minHeight: 'unset',
          [theme.fn.largerThan('md')]: {
            marginTop: 0,
            marginLeft: 'var(--mantine-navbar-width)',
          },
        },
      })}
      padding={0}
      navbar={
        <Navbar width={{ base: 68 }}  hiddenBreakpoint="md" hidden={!opened} classNames={{ root: classes.desktopNavbar }}>
            <Navbar.Section className={classes.navbarItem + ' ' + classes.homeLink}>
              <NavLink to={'/roles'} onClick={() => setOpened(false)}>
                <Image
                src={Logo}
                fit="contain"
                width={30}
                height={'auto'}
                sx={{
                  filter:
                    'drop-shadow(0px 0px 20px rgba(112, 57, 237, 0.9)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.5))',
                }}
              />
            </NavLink>
          </Navbar.Section>

          <div className={classes.linksContainer}>
            {navbarRoutes.map((link) => (
              <Navbar.Section className={classes.navbarItem} key={link.label}>
                <NavbarLink {...link} key={link.label} onClick={() => setOpened(false)} />
              </Navbar.Section>
            ))}
          </div>

          <Navbar.Section className={classes.userMenuContainer}>
            <UserMenu onNavigate={() => setOpened(false)} />
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={56} className={classes.mobileNav} p="md" >
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <MediaQuery largerThan="md" styles={{ display: 'none' }}>
            <Burger
              opened={opened}
              onClick={() => setOpened((o) => !o)}
              size="sm"
              color={theme.colors.gray[6]}
              mr="xl"
            />
          </MediaQuery>
        </div>
      </Header>
      }
    >
      {children}
    </AppShell>
  );
};

export default SiteWrapper;
