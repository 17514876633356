import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  createStyles,
  Group,
  Stack,
  Tabs,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import {
  useGetCompanyQuery,
  useGetUserQuery,
  usePatchUserMutation,
  useUpdatePasswordMutation,
} from 'app/services/rolebot';
import { getNameInitials } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import { useNotifications } from '@mantine/notifications';
import CalendarSettings from 'pages/Profile/CalendarSettings/CalendarSettings';
import {CustomError} from "../../types";

const useStyles = createStyles((theme) => ({
  mainWrapper: {
    margin: '20px 0',
  },

  topSection: {
    flexDirection: 'column',
    [theme.fn.largerThan('md')]: {
      marginInline: 30,
      flexDirection: 'row',
    },
  },

  tabsListWrapper: {
    paddingLeft: 18,
    [theme.fn.largerThan('md')]: {
      paddingLeft: 30,
    },
  },

  infoWrapper: {
    marginInline: 18,
    [theme.fn.largerThan('md')]: {
      marginInline: 10,
    },
  },

  wrapper: {
    paddingInline: 18,
    paddingBottom: 60,
    overflowY: 'auto',
    width: '100%',
    [theme.fn.largerThan('md')]: {
      paddingInline: 30,
      maxWidth: '60%',
    },
  },

  root: {
    height: 170,
    width: 170,
    [theme.fn.largerThan('md')]: {
      height: 150,
      width: 150,
    },
  },

  placeholder: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 60,
    textTransform: 'uppercase',
  },

  editButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
  clicked: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: '#EEEEEE',
  },

  disabled: {
    '&:disabled': {
      color: '#4F4F4F',
      opacity: 1,
    },
  },

  buttonsWrapper: {
    flex: 1,
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    gap: 12,
    button: {
      ':first-of-type': {
        order: 2,
      },
      ':last-of-type': {
        order: 1,
      },
    },
    [theme.fn.largerThan('md')]: {
      flexGrow: 0,
      flexDirection: 'row',
      button: {
        ':first-of-type': {
          order: 1,
        },
        ':last-of-type': {
          order: 2,
        },
      },
    },
  },
  editIcon: {
    height: '16px'
  }
}));

const Profile = () => {
  const { classes } = useStyles();
  const user = useGetUserQuery();
  const company = useGetCompanyQuery();

  let currentTabSelected;
  const { tab } = useParams();

  switch (tab) {
    case 'overview':
      currentTabSelected = 0;
      break;
    case 'calendar':
      currentTabSelected = 1;
      break;
    case 'password':
      currentTabSelected = 3;
      break;
  }

  const [activeTab, setActiveTab] = useState(currentTabSelected);

  return (
    <Box className={classes.mainWrapper}>
      <Group spacing={20} mb={20} className={classes.topSection} align={'center'}>
        <Avatar radius={100} classNames={{ root: classes.root, placeholder: classes.placeholder }}>
          {getNameInitials(user?.data?.user?.name)}
        </Avatar>
        <Stack spacing={0} my={'auto'} mr={'auto'} className={classes.infoWrapper}>
          <Title order={3} color={'#242424'}>
            {user?.data?.user.name}
          </Title>
          <Text size="lg" color={'#838485'}>
            {company?.data?.name}
          </Text>
          {company?.data?.customer_user?.is_admin === 1 && (
            <Text color={'#B3B3B3'} size={'md'} mt={4}>
              Admin
            </Text>
          )}
        </Stack>
      </Group>
      <Tabs
        active={activeTab}
        onTabChange={setActiveTab}
        variant={'unstyled'}
        classNames={{ tabsListWrapper: classes.tabsListWrapper }}
      >
        <Tabs.Tab label={'Overview'}>
          <OverviewTabContent />
        </Tabs.Tab>
        <Tabs.Tab label={'Calendar Settings'}>
          <CalendarSettings />
        </Tabs.Tab>
        <Tabs.Tab label={'Password'}>
          <ChangePasswordTab />
        </Tabs.Tab>
      </Tabs>
    </Box>
  );
};

const OverviewTabFormSchema = z.object({
  name: z.string().nonempty('Name is required').min(8, 'Must be at least 8 characters long'),
  title: z.string().nonempty('Title is required').min(3, 'Must be at least 3 characters long').or(z.literal('')),
  email: z.string().email().nonempty('Email is required'),
  calendar_link: z.string().url('Please provide a valid URL').or(z.literal('')),
  linkedin_profile: z.string().url('Please provide a valid URL').or(z.literal('')),
  phone: z.string().or(z.literal('')),
});

type OverviewTabPayload = z.infer<typeof OverviewTabFormSchema>;

const OverviewTabContent = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { classes, cx } = useStyles();
  const { data } = useGetUserQuery();
  const [patchUser, { isLoading, isSuccess }] = usePatchUserMutation();
  const [lastValidPayload, setLastValidPayload] = useState<OverviewTabPayload | null>(null);

  const defaultValues = useMemo(() => {
    return {
      name: data?.user.name || '',
      title: data?.user.title || '',
      email: data?.user.email || '',
      calendar_link: data?.user.calendar_link || '',
      linkedin_profile: data?.user.linkedin_profile || '',
      phone: data?.user.phone || '',
    };
    // eslint-disable-next-line
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, dirtyFields },
    reset,
    setValue,
  } = useForm({
    defaultValues,
    resolver: zodResolver(OverviewTabFormSchema),
  });

  const handleSave = async (payload: OverviewTabPayload) => {
    let modifiedKeys: string[] = [];

    _.mapKeys(dirtyFields, (value, key) => {
      modifiedKeys.push(key);
    });

    let modifiedPayload: any = {};
    modifiedKeys.map((key) => {
      // @ts-ignore
      modifiedPayload[key] = payload[key];
    });

    if (!modifiedPayload) return;
    _.mapValues(payload, (value, key: 'name' | 'title' | 'email' | 'calendar_link' | 'linkedin_profile' | 'phone') => {
      setValue(key, value);
    });
    
    try {
      await patchUser(modifiedPayload).unwrap();
      setLastValidPayload(payload);
      _.mapValues(
        modifiedPayload,
        (value, key: 'name' | 'title' | 'email' | 'calendar_link' | 'linkedin_profile' | 'phone') => {
          setValue(key, value);
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const onCancel = () => {
    setIsEditing(false);
    if (lastValidPayload) {
      reset(lastValidPayload);
    }
    reset();
  };

  return (
    <div className={classes.wrapper}>
      <Box>
        <ActionIcon
          onClick={isEditing ? onCancel : () => setIsEditing(!isEditing)}
          variant={'transparent'}
          ml={'auto'}
          size={20}
          className={cx(classes.editButton, { [classes.clicked]: isEditing })}
        >
          <FontAwesomeIcon icon={faPen} color={isEditing ? '#351B77' : '#838485'} className={classes.editIcon}/>
        </ActionIcon>
        {/*@ts-ignore*/}
        <form onSubmit={handleSubmit(handleSave)}>
          <Stack sx={{ color: '#4F4F4F' }}>
            <TextInput
              classNames={{ disabled: classes.disabled }}
              label={'Name'}
              size={'md'}
              placeholder={isEditing ? 'Jane Doe' : ''}
              {...register('name')}
              disabled={!isEditing}
              error={errors?.name?.message}
            />
            <TextInput
              classNames={{ disabled: classes.disabled }}
              label={'Title'}
              placeholder={isEditing ? 'Product Designer' : ''}
              size={'md'}
              {...register('title')}
              disabled={!isEditing}
              error={errors?.title?.message}
            />
            <TextInput
              classNames={{ disabled: classes.disabled }}
              label={'Email'}
              size={'md'}
              {...register('email')}
              disabled={true}
              error={errors?.email?.message}
            />
            <TextInput
              classNames={{ disabled: classes.disabled }}
              styles={{ root: { display: 'flex', flexDirection: 'column' }, label: { flex: 1 } }}
              label={
                <Group noWrap position={'apart'}>
                  <Text>Calendar Link</Text>
                  {isEditing && <Text color={'#838485'}>Optional</Text>}
                </Group>
              }
              placeholder={isEditing ? 'e.g. https://calendly.com/example' : ''}
              size={'md'}
              {...register('calendar_link')}
              disabled={!isEditing}
              error={errors?.calendar_link?.message}
            />
            <TextInput
              classNames={{ disabled: classes.disabled }}
              styles={{ root: { display: 'flex', flexDirection: 'column' }, label: { flex: 1 } }}
              label={
                <Group noWrap position={'apart'}>
                  <Text>LinkedIn Profile</Text>
                  {isEditing && <Text color={'#838485'}>Optional</Text>}
                </Group>
              }
              placeholder={isEditing ? 'https://www.linkedin.com/in/example' : ''}
              size={'md'}
              {...register('linkedin_profile')}
              disabled={!isEditing}
              error={errors?.linkedin_profile?.message}
            />
            <TextInput
              classNames={{ disabled: classes.disabled }}
              styles={{ root: { display: 'flex', flexDirection: 'column' }, label: { flex: 1 } }}
              label={
                <Group noWrap position={'apart'}>
                  <Text>Phone</Text>
                  {isEditing && <Text color={'#838485'}>Optional</Text>}
                </Group>
              }
              placeholder={isEditing ? '(310) 456 7898' : ''}
              size={'md'}
              {...register('phone')}
              disabled={!isEditing}
              error={errors?.phone?.message}
            />
          </Stack>
          {isEditing && (
            <Group position={'right'} mt={40} className={classes.buttonsWrapper}>
              <Button px={40} variant={'outline'} onClick={onCancel} disabled={isLoading}>
                Cancel
              </Button>
              <Button px={40} disabled={!isDirty} type={'submit'} loading={isLoading}>
                Save Changes
              </Button>
            </Group>
          )}
        </form>
      </Box>
    </div>
  );
};

const ChangePasswordTabFormSchema = z
  .object({
    current_password: z.string().nonempty('Your current password is required'),
    password: z
      .string()
      .min(8, 'Password must be at least 8 characters long')
      .refine((v) => v.match(/[A-Z]/), 'Password must contain at least one uppercase letter')
      .refine((v) => v.match(/[a-z]/), 'Password must contain at least one lowercase letter'),
    confirm: z.string(),
  })
  .refine((data) => data.password === data.confirm, {
    message: 'New Password and Confirm Password must match',
    path: ['confirm'],
  });

type ChangePasswordFormPayload = z.infer<typeof ChangePasswordTabFormSchema>;

const initialState: Record<string, boolean> = {
  showCurrent: true,
  showNew: true,
  showConfirm: true,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'toggle': {
      return {
        ...state,
        [action.field]: !state[action.field],
      };
    }
  }
};

const ChangePasswordTab = () => {
  const notifications = useNotifications();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { classes } = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      current_password: '',
      password: '',
      confirm: '',
    },
    resolver: zodResolver(ChangePasswordTabFormSchema),
  });

  const [updatePassword, { isLoading, isSuccess, reset: resetMutation }] = useUpdatePasswordMutation();

  const handleUpdatePassword = async (data: ChangePasswordFormPayload) => {
    const payload = {
      old_password: data.current_password,
      password: data.password,
    };

    try {
      await updatePassword(payload).unwrap();
    } catch (e: unknown) {

      const error = e as CustomError;
      notifications.showNotification({
        title: error.data.message,
        color: 'red',
        message: '',
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      notifications.showNotification({
        title: 'Password updated successfully!',
        color: 'green',
        message: '',
      });
      reset();
      resetMutation();
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  return (
    <div className={classes.wrapper}>
      <Box>
        <Text mt={10} mb={20} color={'#191D29'} size={'lg'}>
          Change your password
        </Text>
        <Text color={'#838485'} mb={20}>
          A secure password helps protect your Rolebot account
        </Text>
        {/*@ts-ignore*/}
        <form onSubmit={handleSubmit(handleUpdatePassword)}>
          <Stack>
            <TextInput
              classNames={{ disabled: classes.disabled }}
              label={'Current Password'}
              placeholder={'********'}
              size={'md'}
              {...register('current_password')}
              error={errors?.current_password?.message}
              type={state.showCurrent ? 'password' : 'text'}
              rightSectionProps={{ style: { marginRight: 15 } }}
              rightSection={
                <Text
                  size={'sm'}
                  color={'#838485'}
                  onClick={() => dispatch({ type: 'toggle', field: 'showCurrent' })}
                  sx={{ cursor: 'pointer', userSelect: 'none', fontSize: 12 }}
                >
                  {state.showCurrent ? 'SHOW' : 'HIDE'}
                </Text>
              }
            />
            <TextInput
              classNames={{ disabled: classes.disabled }}
              label={'New Password'}
              placeholder={'8+ characters'}
              size={'md'}
              {...register('password')}
              error={errors?.password?.message}
              type={state.showNew ? 'password' : 'text'}
              rightSectionProps={{ style: { marginRight: 15 } }}
              rightSection={
                <Text
                  size={'sm'}
                  color={'#838485'}
                  onClick={() => dispatch({ type: 'toggle', field: 'showNew' })}
                  sx={{ cursor: 'pointer', userSelect: 'none', fontSize: 12 }}
                >
                  {state.showNew ? 'SHOW' : 'HIDE'}
                </Text>
              }
            />
            <TextInput
              classNames={{ disabled: classes.disabled }}
              label={'Confirm Password'}
              placeholder={'********'}
              size={'md'}
              {...register('confirm')}
              error={errors?.confirm?.message}
              type={state.showConfirm ? 'password' : 'text'}
              rightSectionProps={{ style: { marginRight: 15 } }}
              rightSection={
                <Text
                  size={'sm'}
                  color={'#838485'}
                  onClick={() => dispatch({ type: 'toggle', field: 'showConfirm' })}
                  sx={{ cursor: 'pointer', userSelect: 'none', fontSize: 12 }}
                >
                  {state.showConfirm ? 'SHOW' : 'HIDE'}
                </Text>
              }
            />
          </Stack>
          <Group position={'right'} mt={40}>
            <Button px={40} disabled={!isValid || !isDirty} type={'submit'} loading={isLoading}>
              Update Password
            </Button>
          </Group>
        </form>
      </Box>
    </div>
  );
};

export default Profile;
