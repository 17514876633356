import { Stack, Tabs, Title } from '@mantine/core';
import Accounts from './Accounts';

const Security = () => {
  
  return (
    <div>
      <Stack mb={30}>
        <Title order={2} style={{color:'#242424'}}>
          Security
        </Title>
      </Stack>

      <Tabs variant={'unstyled'} mb={30}>
        <Tabs.Tab label="Accounts">
            <Accounts />
        </Tabs.Tab>
      </Tabs>
    </div>
  );
};

export default Security;
