import {
  Badge,
  Box,
  Container,
  createStyles,
  Group,
  Stack,
  Tabs,
  Text,
  Title,
  Tooltip,
  Avatar,
} from '@mantine/core';
import RolebotTabs from 'components/RolebotTabs/RolebotTabs';
import RoleMenu from 'components/RoleMenu/RoleMenu';
import TalentDrawer from 'components/TalentDetail/TalentDrawer';
import TalentStack from 'components/TalentStack/TalentStack';
import { useEffect, useReducer, useRef, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetRoleByIdQuery, useGetRoleLastReviewedByQuery, useGetUserQuery } from 'app/services/rolebot';
import useAppSelector from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { closeTalentMenu, setCurrentTalentId } from 'features/app/appSlice';
import CandidateToReviewSuccess from 'components/Notifications/CandidateToReviewSuccess/CandidateToReviewSuccess';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { faClockFour } from '@fortawesome/pro-light-svg-icons';
import { RolebotClient } from 'api/client';
import moment from 'moment';
import { getNameInitials } from 'utils';
import useOpenModal from 'hooks/useOpenModal';
import { toggleRoleMenu } from 'features/app/appSlice';
import { useNotifications } from '@mantine/notifications';
import { HttpError } from 'types';
import Echo from 'utils/echo';
import MoreCandidatesComingUp from 'components/MoreCandidatesComingUp/MoreCandidatesComingUp';
import FeedbackLoadingScreen from 'components/Notifications/FeedbackLoadingScreen/FeedbackLoadingScreen';
import FeedbackTalentNotFound from 'components/Notifications/FeedbackTalentNotFound/FeedbackTalentNotFound';
import FeedbackRejectionScreen from 'components/Notifications/FeedbackRejectionScreen/FeedbackRejectionScreen';
import RoleAllSetScreen from 'components/Notifications/RoleAllSetScreen/RoleAllSetScreen';
import { toastWarning } from 'utils/toastify-messages';

const useStyles = createStyles((theme) => ({
  container: {
    paddingInline: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.fn.largerThan('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    [theme.fn.largerThan('md')]: {
      paddingInline: 30,
    },
  },

  backToAllRolesButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '10px',
    maxWidth: 'fit-content',
    [theme.fn.largerThan('md')]: {
      margin: '10px 0',
    },
  },

  roleHeader: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '0 10px',
    [theme.fn.largerThan('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  roleHeaderOptions: {
    width: '100%',
    justifyContent: 'space-between',

    [theme.fn.largerThan('md')]: {
      justifyContent: 'flex-end',
      width: 'auto',
    },
  },

  noItemsTitle: {
    color: '#242424',
    fontSize: 28,
    [theme.fn.largerThan('md')]: {
      fontSize: 34,
    },
  },

  placeholderContainer: {
    paddingInline: 25,
    gap: 10,
    [theme.fn.largerThan('md')]: {
      paddingInline: 0,
      gap: 0,
    },
  },

  col: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  flexWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },

  inputIcon: {
    height: 50,
    width: 50,
    marginLeft: 15,
  },
  avatar: {
    width: '1vw',
    height: '4vh',
    borderRadius: 35,
    [theme.fn.largerThan('md')]: {
      width: '2vw',
      height: '4.2vh',
      borderRadius: 60,
      padding: '2px',
    },
  },
  placeholder: {
    width: '5vw',
    height: '4vh',
    borderRadius: 35,
    [theme.fn.largerThan('md')]: {
      width: '4vh',
      height: '4vh',
      borderRadius: 40,
    },
    h2: {
      fontSize: 14,
      [theme.fn.largerThan('md')]: {
        fontSize: 15,
        margin: '2px',
      },
    },
  },
  button: {
    backgroundColor: 'transparent',
    color: '#838485',
    borderRadius: 6,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
    [theme.fn.largerThan('md')]: {
      width: 'auto',
      padding: 10,
      marginLeft: 'auto',
      marginRight: 20,
    },
  },

}));

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'getRoleStatsReturned':
      return {
        ...state,
        reviewTalentCount: action.payload.review,
        acceptedTalentCount: action.payload.accepted,
        declinedTalentCount: action.payload.declined,
        skippedTalentCount: action.payload.skipped,
        unavailableTalentCount: action.payload.unavailable,
        interviewTalentCount: action.payload.interview,
        totalTalentNumber: action.payload.review + action.payload.accepted + action.payload.declined + action.payload.skipped + action.payload.unavailable + action.payload.interview,
        roleStats: action.payload,
        feedbackEnabled: action.payload.feedback_enabled,
        feedbackMode: action.payload.feedback_mode
      };
    case 'getLastReviewedInfoReturned':
      return {
        ...state,
        lastReviewedDate: action.payload.role?.talent_reviewed_date,
        lastReviewedByUsername: action.payload.role.last_reviewed_by?.name,
        lastReviewedByEmail: action.payload.role.last_reviewed_by?.email,
        isLastReviewedByInactive: action.payload.role.last_reviewed_by?.deleted_at ? true : false
      };
    case 'getTalentsReturned':
      const { data, type, total } = action.payload;

      let reviewTalent = state.review;
      let acceptedTalent = state.accepted;
      let declinedTalent = state.declined;
      let skippedTalent = state.skipped;
      let unavailableTalent = state.unavailable;
      let interviewTalent = state.interview;
      let mergedTalent = state.mergedTalent;

      if (type === 'review') {
        reviewTalent = [...reviewTalent, ...data];
        reviewTalent = [
          ...new Map(reviewTalent.map((talent: { id: any }) => [talent.id, talent])).values(),
        ];
        mergedTalent = [...mergedTalent, ...reviewTalent];
      }

      if (type === 'accepted') {
        acceptedTalent = [...acceptedTalent, ...data];
        acceptedTalent = [
          ...new Map(acceptedTalent.map((talent: { id: any }) => [talent.id, talent])).values(),
        ];
        mergedTalent = [...mergedTalent, ...acceptedTalent];
      }

      if (type === 'declined') {
        declinedTalent = [...declinedTalent, ...data];
        declinedTalent = [
          ...new Map(declinedTalent.map((talent: { id: any }) => [talent.id, talent])).values(),
        ];
        mergedTalent = [...mergedTalent, ...declinedTalent];
      }

      if (type === 'skipped') {
        skippedTalent = [...skippedTalent, ...data];
        skippedTalent = [
          ...new Map(skippedTalent.map((talent: { id: any }) => [talent.id, talent])).values(),
        ];
        mergedTalent = [...mergedTalent, ...skippedTalent];
      }

      if (type === 'unavailable') {
        unavailableTalent = [...unavailableTalent, ...data];
        unavailableTalent = [
          ...new Map(unavailableTalent.map((talent: { id: any }) => [talent.id, talent])).values(),
        ];
        mergedTalent = [...mergedTalent, ...unavailableTalent];
      }

      if (type === 'interview') {
        interviewTalent = [...interviewTalent, ...action.payload.data];
        interviewTalent = [
          ...new Map(interviewTalent.map((talent: { id: any }) => [talent.id, talent])).values(),
        ];
        mergedTalent = [...mergedTalent, ...interviewTalent];
      }

      return {
        ...state,
        isFetching: false,
        review: reviewTalent,
        accepted: acceptedTalent,
        declined: declinedTalent,
        skipped: skippedTalent,
        unavailable: unavailableTalent,
        interview: interviewTalent,
        mergedTalent: mergedTalent,
        reviewTalentCount: type === "review" ? total : state.reviewTalentCount,
        acceptedTalentCount: type === "accepted" ? total : state.acceptedTalentCount,
        declinedTalentCount: type === "declined" ? total : state.declinedTalentCount,
        skippedTalentCount: type === "skipped" ? total : state.skippedTalentCount,
        unavailableTalentCount: type === "unavailable" ? total : state.unavailableTalentCount,
        interviewTalentCount: type === "interview" ? total : state.interviewTalentCount,
        isRoleInFeedbackLoop: state.isRoleInFeedbackLoop,
        isFeedbackEnabled: state.isFeedbackEnabled
      };
    case 'onTabChange':
      return {
        ...state,
        activeTabIndex: action.payload.index,
        activeTab: action.payload.tabKey
      };
    case 'onRequestMade':
      return {
        ...state,
        isFetching: true
      };
    case 'onTalentSelected':
      return {
        ...state,
        areButtonsActive: action.payload,
      };
    case 'onTalentFiltered':
      const { tab, talentList } = action.payload;

      return {
        ...state,
        review: tab === 'review' ? talentList : state.review,
        accepted: tab === 'accepted' ? talentList : state.accepted,
        declined: tab === 'declined' ? talentList : state.declined,
        skipped: tab === 'skipped' ? talentList : state.skipped,
        reviewTalentCount: tab === 'review' ? state.reviewTalentCount - 1 : state.reviewTalentCount,
        acceptedTalentCount: tab === 'accepted' ? state.acceptedTalentCount - 1 : state.acceptedTalentCount,
        declinedTalentCount: tab === 'declined' ? state.declinedTalentCount - 1 : state.declinedTalentCount,
        skippedTalentCount: tab === 'skipped' ? state.skippedTalentCount - 1 : state.skippedTalentCount
      };
    case 'onResetTalentTab':
      return {
        ...state,
        accepted: action.payload.tab === 'accepted' ? [] : state.accepted,
        declined: action.payload.tab === 'declined' ? [] : state.declined,
        skipped: action.payload.tab === 'skipped' ? [] : state.skipped
      };
    case 'onTalentAccepted':
      return {
        ...state,
        acceptedTalentCount: state.acceptedTalentCount + 1
      };
    case 'onTalentDeclined':
      return {
        ...state,
        declinedTalentCount: state.declinedTalentCount + 1
      };
    case 'onTalentSkipped':
      return {
        ...state,
        skippedTalentCount: state.skippedTalentCount + 1
      };
    case 'updateShowConfettiAnimation':
      return {
        ...state,
        showConfettiAnimation: !state.showConfettiAnimation
      };
    case 'updateShowFeedbackRejectionScreen':
      return {
        ...state,
        feedbackShowRejectionScreen: !state.feedbackShowRejectionScreen
      }
    case 'onAdjustSearchCriteriaCompleted':
      return {
        ...state,
        showFeedbackLoadingScreen: true,
        showNoTalentFoundScreen: false,
        showRejectionScreen: false,
        showRolebotDancingScreen: false,
        showRoleAllSetScreen: false,
        showTalentToReviewScreen: false,
        feedbackMode: true
      }
    case 'enableFeedbackLoadingScreen':
      return {
        ...state,
        showFeedbackLoadingScreen: true,
        showNoTalentFoundScreen: false,
        showRejectionScreen: false,
        showRolebotDancingScreen: false,
        showRoleAllSetScreen: false,
        showTalentToReviewScreen: false
      }
    case 'enableNoTalentFoundScreen':
      return {
        ...state,
        showNoTalentFoundScreen: true,
        showFeedbackLoadingScreen: false,
        showRejectionScreen: false,
        showRolebotDancingScreen: false,
        showRoleAllSetScreen: false,
        showTalentToReviewScreen: false
      }
    case 'enableRejectionScreen':
      return {
        ...state,
        showRejectionScreen: true,
        showFeedbackLoadingScreen: false,
        showNoTalentFoundScreen: false,
        showRolebotDancingScreen: false,
        showRoleAllSetScreen: false,
        showTalentToReviewScreen: false
      }
    case 'enableRolebotDancingScreen':
      return {
        ...state,
        showRolebotDancingScreen: true,
        showFeedbackLoadingScreen: false,
        showNoTalentFoundScreen: false,
        showRejectionScreen: false,
        showRoleAllSetScreen: false,
        showTalentToReviewScreen: false
      }
    case 'enableRoleAllSetScreen':
      return {
        ...state,
        showRoleAllSetScreen: true,
        showFeedbackLoadingScreen: false,
        showNoTalentFoundScreen: false,
        showRejectionScreen: false,
        showRolebotDancingScreen: false,
        showTalentToReviewScreen: false
      }
    case 'enableTalentToReviewScreen':
      return {
        ...state,
        showTalentToReviewScreen: true,
        showFeedbackLoadingScreen: false,
        showNoTalentFoundScreen: false,
        showRejectionScreen: false,
        showRolebotDancingScreen: false,
        showRoleAllSetScreen: false
      }
    case 'enableHideTabScreen':
      return {
        ...state,
        showTalentToReviewScreen: false,
        showFeedbackLoadingScreen: false,
        showNoTalentFoundScreen: false,
        showRejectionScreen: false,
        showRolebotDancingScreen: false,
        showRoleAllSetScreen: false
      }
    case 'setShowTalentToReviewScreen':
      return {
        ...state,
        showTalentToReviewScreen: action.payload,
        showFeedbackLoadingScreen: false,
        showNoTalentFoundScreen: false,
        showRejectionScreen: false,
        showRolebotDancingScreen: false,
        showRoleAllSetScreen: false
      }
    case 'resetAllReviewScreens':
      return {
        ...state,
        showFeedbackLoadingScreen: false,
        showNoTalentFoundScreen: false,
        showRejectionScreen: false,
        showRolebotDancingScreen: false,
        showRoleAllSetScreen: false,
        showTalentToReviewScreen: false,
        reviewTalentCount: action.payload.review,
        acceptedTalentCount: action.payload.accepted,
        declinedTalentCount: action.payload.declined,
        skippedTalentCount: action.payload.skipped,
        unavailableTalentCount: action.payload.unavailable,
        interviewTalentCount: action.payload.interview,
        totalTalentNumber: action.payload.review + action.payload.accepted + action.payload.declined + action.payload.skipped + action.payload.unavailable + action.payload.interview,
        feedbackEnabled: action.payload.feedback_enabled,
        feedbackMode: action.payload.feedback_mode,
        feedbackNoTalentFound: false
      }
    case 'onFetchingEnd':
      return {
        ...state,
        isFetching: false
      };
    case 'onRoleLoad':
      const firstUserSearch = action.payload.user_searches?.[0];
      const feedbackSuggestionsCount = firstUserSearch ? (firstUserSearch.feedback_companies_count + firstUserSearch.feedback_job_titles_count + firstUserSearch.feedback_keywords_count + firstUserSearch.feedback_skills_count) : 0;
      return {
        ...state,
        skillsSuggestionsCount: action.payload.search_criteria_skills_suggestions_count,
        titlesSuggestionsCount: action.payload.search_criteria_titles_suggestions_count,
        feedbackSuggestionsCount: feedbackSuggestionsCount,
        industriesSuggestionsCount: action.payload.search_criteria_industries_suggestions_count
      }
    case 'setInitialReviewScreenState':
      return {
        ...state,
        initialReviewScreenState: action.payload
      }
    default:
      throw new Error();
  }
}

type RoleParams = {
  roleId: string;
};

const Role = () => {
  const params = useParams<RoleParams>();
  const roleId = params.roleId!;
  const navigate = useNavigate();
  const { classes } = useStyles();
  const appDispatch = useAppDispatch();
  const { data: extraRoleData, isFetching } = useGetRoleByIdQuery(roleId.toString());
  const user = useGetUserQuery();
  const userId = user?.data?.user?.id;
  const currentTalent = useAppSelector((state: any) => state.app.currentTalent);
  const lastReviewedInfo = useGetRoleLastReviewedByQuery(roleId);
  const { roleSetup, inactivePlanModal, provideFeedbackModal } = useOpenModal();
  const notifications = useNotifications();
  const notificationId = useRef<string | null>(null);
  const feedbackNotificationId = useRef<string | null>(null);
  const [reloadReviewTalent, setReloadReviewTalent] = useState(false);
  const dispatchCompleted = useRef(false);
  const [shouldTriggerFeedbackFlow, setShouldTriggerFeedbackFlow] = useState(false);

  const [state, dispatch] = useReducer(reducer, {
    isFetching: false,
    isFirstRender: true,
    activeTabIndex: -1,
    activeTab: null,
    roleStats: null,
    review: [],
    reviewTalentCount: 0,
    accepted: [],
    acceptedTalentCount: 0,
    declined: [],
    declinedTalentCount: 0,
    skipped: [],
    skippedTalentCount: 0,
    unavailable: [],
    unavailableTalentCount: 0,
    interview: [],
    interviewTalentCount: 0,
    selectedTalent: null,
    mergedTalent: [],
    totalTalentNumber: 10,
    perPage: 30,
    areButtonsActive: true,
    showConfettiAnimation: false,
    lastReviewedDate: null,
    lastReviewedByUsername: null,
    lastReviewedByEmail: null,
    isLastReviewedByInactive: false,
    skillsSuggestionsCount: 0,
    titlesSuggestionsCount: 0,
    feedbackSuggestionsCount: 0,
    feedbackEnabled: null,
    feedbackMode: null,
    feedbackNoTalentFound: null,
    feedbackShowRejectionScreen: null,
    showFeedbackLoadingScreen: false,
    showNoTalentFoundScreen: false,
    showRejectionScreen: false,
    showRolebotDancingScreen: false,
    showRoleAllSetScreen: false,
    showTalentToReviewScreen: false,
  });

  const getTalentList = (request: { 'talent-filter-type': string; page: number; 'per_page': number; }) => {
    (async () => {
      try {
        const { data } = await RolebotClient.get('/roles/' + roleId + '/talent', { params: request })
        dispatch({ type: 'getTalentsReturned', payload: { ...data, type: request['talent-filter-type'] } });
        if (reloadReviewTalent) {
          setReloadReviewTalent(false);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  };

  const getPageNumber = () => {
    if (!state.activeTab) return 1;

    const talentCount = state[state.activeTab + 'TalentCount'];
    const currentTalent = state[state.activeTab];

    if (!currentTalent) return 1;

    if (currentTalent.length === 0) return 1;

    if (talentCount - currentTalent.length === 0) return -1;

    let pageNumber = Math.ceil(currentTalent.length / state.perPage);
    return ++pageNumber;
  };

  const handleGetMoreTalent = () => {
    if ((state.activeTab && !state.isFetching) || reloadReviewTalent) {
      const pageNumber = getPageNumber();
      if (pageNumber !== -1) {
        const request = {
          'talent-filter-type': reloadReviewTalent ? 'review' : state.activeTab,
          page: pageNumber,
          'per_page': state.perPage,
        };
        dispatch({ type: 'onRequestMade' })
        getTalentList(request);
      }
    }
  };

  const NoTalentFoundMessage = (
    <Text
      style={{
        fontFamily: 'Helvetica',
        fontSize: '13px'
      }}
    >
      We didn't find any matches for your "{extraRoleData?.name}" role. Hang tight as Rolebot continues to search. We'll notify you once we find new matches!
    </Text>
  );

  useEffect(() => {
    if (process.env.REACT_APP_WEB_SOCKETS_DISABLED === 'false' || !process.env.REACT_APP_WEB_SOCKETS_DISABLED) {
      Echo.channel(`no-talent-found-in-feedback-flow${extraRoleData?.id}-${user?.data?.user?.id}`).listen('NoTalentFoundInFeedbackFlow', async (e: any) => {
        toastWarning(
          <div className="toastContainer">
            <div>
              No matches yet...
            </div>
            <div
              style={{
                fontSize: '16px',
                color: '#838485',
                fontWeight: 400,
                fontFamily: 'Helvetica',
              }}
            >
              {NoTalentFoundMessage}
            </div>
          </div>
        );
      });
      return () => Echo.leaveChannel(`no-talent-found-in-feedback-flow${extraRoleData?.id}-${user?.data?.user?.id}`);
    }
  }, [user, extraRoleData?.id]);

  const handleTabChange = (index: number, tabKey: string | undefined) => {
    console.log('changing shouldTriggerFeedbackFlow to false');
    setShouldTriggerFeedbackFlow(false);
    if (state.activeTabIndex === 0 && index !== 0 && state.reviewTalentCount === 0 && state.feedbackMode !== true) {
      dispatch({ type: 'setShowTalentToReviewScreen', payload: false });
    }

    dispatch({ type: 'onTabChange', payload: { index: index, tabKey: tabKey } });
    dispatch({ type: 'onFetchingEnd' });
  }

  const handleSetInitialActiveTab = (isRoleCompleted: boolean) => {
    let index = 0;
    let tabKey = "";

    if (!state.roleStats) return;

    if ((state.roleStats.review > 0 && !isRoleCompleted) || state.feedbackMode === true) {
      index = 0;
      tabKey = "review";
    }
    else if (state.roleStats.accepted > 0) {
      index = 1;
      tabKey = "accepted";
    }
    else if (state.roleStats.declined > 0) {
      index = 2;
      tabKey = "declined";
    }
    else if (state.roleStats.skipped > 0) {
      index = 3;
      tabKey = "skipped";
    }
    else if (state.roleStats.unavailable > 0) {
      index = 4;
      tabKey = "unavailable";
    }
    else if (state.roleStats.interview > 0) {
      index = 5;
      tabKey = "interview";
    }

    if (tabKey !== "") {
      handleTabChange(index, tabKey);
    }
  }

  const handleGetRoleStats = async () => {
    try {
      const { data } = await RolebotClient.get('/roles/' + roleId + '/stats');
      dispatch({ type: 'getRoleStatsReturned', payload: data.role });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_WEB_SOCKETS_DISABLED === 'false' || !process.env.REACT_APP_WEB_SOCKETS_DISABLED) {
      Echo.channel(`load-role-first-batch${extraRoleData?.id}`).listen('LoadRoleFirstBatch', async (e: any) => {
        try {
          console.log('LoadRoleFirstBatch webhook here!');
          //We refresh the role stats here to know if new talent has been added to the role
          const { data } = await RolebotClient.get('/roles/' + roleId + '/stats');
          dispatch({ type: 'resetAllReviewScreens', payload: data.role });
          if (e.isTalentFound === false) {
            setShouldTriggerFeedbackFlow(true);
            console.log('setting shouldTriggerFeedbackFlow to true', shouldTriggerFeedbackFlow);
            dispatch({ type: 'enableNoTalentFoundScreen' });
          } else {
            console.log('setting shouldTriggerFeedbackFlow to false');
            setShouldTriggerFeedbackFlow(false);
            setReloadReviewTalent(true);
            handleGetMoreTalent();
            dispatch({ type: 'enableTalentToReviewScreen' });
          }
        } catch (error) {
          console.log(error);
        }
      });
      return () => Echo.leaveChannel(`load-role-first-batch${extraRoleData?.id}`);
    }
  }, [extraRoleData]);

  useEffect(() => {
    if (state.activeTab) {
      handleGetMoreTalent();
    }
  }, [state.activeTab]);

  useEffect(() => {
    if (reloadReviewTalent) {
      handleGetMoreTalent();
    }
  }, [reloadReviewTalent]);

  const {
    data: role,
    isError,
    error,
    isSuccess,
  } = useGetRoleByIdQuery(roleId || '', {
    skip: roleId === '',
    refetchOnMountOrArgChange: true
  });

  const isRoleInFeedbackLoop = useMemo(() => {
    return state.roleStats?.feedback_mode;
  }, [state.roleStats]);

  const initialReviewScreenStateRef = useRef<string | null>(null);

  //To validate this logic, we can review this spreadsheet:
  //https://docs.google.com/spreadsheets/d/1aaPqYrVRl3x6a0nHXxOlGwWr69xpSSBdRxFh5Zw1YUU/edit?gid=0#gid=0
  useEffect(() => {
    if (state.roleStats && initialReviewScreenStateRef.current === null) {
      let screenState: string;

      if (state.feedbackEnabled === true && state.feedbackMode === true) {
        screenState = 'feedbackLoading';
      }  else if (state.totalTalentNumber === 0) {
        screenState = 'roleAllSet';
      } else if (state.reviewTalentCount > 0) {
        screenState = 'talentToReview';
      } else {
        screenState = 'hideTab';
      }

      initialReviewScreenStateRef.current = screenState;

      // Dispatch an action to update the state with the initial review screen state
      dispatch({ type: 'setInitialReviewScreenState', payload: screenState });
    }
  }, [state.roleStats, state.feedbackEnabled, state.feedbackMode, state.feedbackNoTalentFound]);

  const reviewScreenState = initialReviewScreenStateRef.current;

  useEffect(() => {
    if (reviewScreenState) {
      dispatch({ type: `enable${reviewScreenState.charAt(0).toUpperCase() + reviewScreenState.slice(1)}Screen` });
      dispatchCompleted.current = true;
    }
  }, [reviewScreenState]);

  useEffect(() => {
    if (dispatchCompleted.current) {
      handleSetInitialActiveTab(false);
      dispatchCompleted.current = false;
    }
  }, [dispatchCompleted.current]);

  useEffect(() => {
    handleGetRoleStats();
  }, [roleId]);

  useEffect(() => {
    if (lastReviewedInfo.data) {
      dispatch({ type: 'getLastReviewedInfoReturned', payload: lastReviewedInfo.data });
    }
  }, [lastReviewedInfo.data])

  // If we get a 404 error precisely when trying to get a role by id,
  // redirect to the designated 404 page
  useEffect(() => {
    const typedError = error as HttpError;
    if (typedError && typedError.status === 404) {
      navigate('/404');
    }
  }, [error]);

  useEffect(() => {
    if (role?.completed) {
      handleSetInitialActiveTab(true);
    }
  }, [role?.completed]);

  useEffect(() => {
    if (role) {
      dispatch({ type: 'onRoleLoad', payload: role });
    }
  }, [role]);

  useEffect(() => {
    if (role?.id) {
      if (process.env.REACT_APP_WEB_SOCKETS_DISABLED === 'false' || !process.env.REACT_APP_WEB_SOCKETS_DISABLED) {
        const channel = Echo.channel(`update-search-criteria${role.id}`);
        channel.listen('AskUserToUpdateSearchCriteria', (e: any) => {
          console.log('provideFeedback webhook here!');
          provideFeedbackModal({
            handleOpenSearchCriteria: handleOpenSearchCriteriaFromFeedback
          });
          setShouldTriggerFeedbackFlow(true);
          console.log('setting shouldTriggerFeedbackFlow to true', shouldTriggerFeedbackFlow);
        });

        return () => {
          Echo.leaveChannel(`update-search-criteria${role.id}`);
        };
      }
    }
  }, [role?.id]);

  const handleReviewClick = () => {
    if (notificationId.current) {
      notifications.hideNotification(notificationId.current);
    }

    if (extraRoleData?.customer?.is_active) {
      handleOpenSearchCriteria()
    }
    else {
      inactivePlanModal({})
    }
  };

  const SuggestionsMessage = (
    <Text
      style={{
        fontFamily: 'Helvetica',
        fontSize: '16px',
        fontWeight: 400
      }}
    >
      New Search Criteria suggestions for this role. <a onClick={handleReviewClick} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#249FC8' }}>Review them here</a>.
    </Text>
  );

  useEffect(() => {
    const notificationShownKey = `suggestionsNotificationShown-${roleId}`;
    const notificationShown = localStorage.getItem(notificationShownKey);

    if ((state.skillsSuggestionsCount > 0 || state.titlesSuggestionsCount > 0) && !notificationShown) {
      let id = notifications.showNotification({
        icon: <FontAwesomeIcon
          icon={faCircleInfo}
          color={'#D6328AD9'}
        />,
        message: SuggestionsMessage,
        autoClose: false,
        onClose: () => {
          localStorage.setItem(notificationShownKey, 'true');
          notificationId.current = null;
        },
        styles: (theme) => ({
          root: {
            borderColor: '#D6328AD9',
            borderWidth: '1px',
            borderStyle: 'solid',
            backGroundColor: 'transparent'
          },
          icon: {
            backgroundColor: 'transparent !important',
            width: '24px'
          }
        })
      });

      notificationId.current = id;

      // Cleanup function to hide the notification when the component unmounts or conditions change
      return () => {
        notifications.hideNotification(id);
      };
    }
  }, [state.skillsSuggestionsCount, state.titlesSuggestionsCount]);

  const FeedbackMessage = (
    <Text
      style={{
        fontFamily: 'Helvetica',
        fontSize: '14px',
        fontWeight: 400
      }}
    >
      Your feedback has been analyzed by AI. New Search Criteria added. Review changes <a onClick={handleReviewClick} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#249FC8' }}>here</a>.
    </Text>
  );

  useEffect(() => {
    const notificationShownKey = `feedbackSuggestionsNotificationShown-${roleId}`;
    const notificationShown = localStorage.getItem(notificationShownKey);

    if (state.feedbackSuggestionsCount > 0 && !notificationShown) {
      let id = notifications.showNotification({
        icon: <FontAwesomeIcon
          icon={faCircleInfo}
          color={'#D6328AD9'}
        />,
        title: 'Feedback Analyzed',
        message: FeedbackMessage,
        autoClose: false,
        onClose: () => {
          localStorage.setItem(notificationShownKey, 'true');
          feedbackNotificationId.current = null;
        },
        styles: (theme) => ({
          root: {
            borderColor: '#D6328AD9',
            borderWidth: '1px',
            borderStyle: 'solid',
            backGroundColor: 'transparent'
          },
          icon: {
            backgroundColor: 'transparent !important',
            width: '24px'
          },
          title: {
            color: '#242424',
            fontSize: '15px',
            fontWeight: 'bold'
          }
        })
      });

      feedbackNotificationId.current = id;

      // Cleanup function to hide the notification when the component unmounts or conditions change
      return () => {
        notifications.hideNotification(id);
      };
    }
  }, [state.feedbackSuggestionsCount]);

  const handleFilterSelectedTalents = (talentId: number | string) => {
    const activeTab = state.activeTab;
    let currentTalentList = state[activeTab].filter((talent: any) => talent.id !== talentId);
    dispatch({ type: 'onTalentFiltered', payload: { tab: activeTab, talentList: currentTalentList } });
  }

  const updateCurrentTalentId = () => {
    let talentList = state[state.activeTab];

    let currentTalentIndex = talentList.findIndex(function (talent: { id: any; }) {
      return talent.id === currentTalent?.id;
    });

    if (currentTalentIndex === -1) return;

    if (currentTalentIndex === talentList.length - 1) {
      appDispatch(closeTalentMenu())
    } else {
      appDispatch(setCurrentTalentId(talentList[++currentTalentIndex]));
    }
  };

  useEffect(() => {
    console.log('shouldTriggerFeedbackFlow => ', shouldTriggerFeedbackFlow);
    if (shouldTriggerFeedbackFlow) {
      console.log('Feedback flow to be triggered');
    }
  }, [shouldTriggerFeedbackFlow]);

  const handlePatchCompleted = (action: string, talentId: number | string, showRejectionScreen: boolean | null) => {
    console.log('from role page, showRejectionScreen flag => ', showRejectionScreen);
    if (action === "accepted") {
      dispatch({ type: 'onTalentAccepted' });
    }

    if (action === "declined") {
      dispatch({ type: 'onTalentDeclined' });
    }

    if (action === "skipped") {
      dispatch({ type: 'onTalentSkipped' });
    }

    dispatch({ type: 'onTalentSelected', payload: true });

    if (state.activeTab === "accepted" || state.activeTab === "declined" || state.activeTab === "skipped") {
      if (state[state.activeTab] && state[state.activeTab].length === 1 && state[state.activeTab][0].id === talentId) {
        handleGetRoleStats();
      }
    }

    if (showRejectionScreen === false) {
      dispatch({ type: 'enableRolebotDancingScreen' });
    }

    if (state.activeTab === 'review' && showRejectionScreen === true && state.roleStats.feedback_enabled === true) {
      setShouldTriggerFeedbackFlow(true);
      console.log('setting shouldTriggerFeedbackFlow to true', shouldTriggerFeedbackFlow);
      provideFeedbackModal({
        handleOpenSearchCriteria: () => handleOpenSearchCriteriaFromFeedback(true)
      });
      dispatch({ type: 'enableRejectionScreen' });
    }

    if (state.activeTab === 'review' && state.roleStats.feedback_enabled === false && showRejectionScreen === true) {
      dispatch({ type: 'enableRolebotDancingScreen' });
    }
  }

  const handleSelectTalent = (talentId: number | string) => {
    dispatch({ type: 'onTalentSelected', payload: false });

    if (currentTalent) {
      updateCurrentTalentId();
    }

    handleFilterSelectedTalents(talentId);

    if (state.activeTab === "review") {
      dispatch({ type: 'onResetTalentTab', payload: { tab: "accepted" } });
      dispatch({ type: 'onResetTalentTab', payload: { tab: "declined" } });
      dispatch({ type: 'onResetTalentTab', payload: { tab: "skipped" } });
    }

    if (state.activeTab === "accepted") {
      dispatch({ type: 'onResetTalentTab', payload: { tab: "declined" } });
    }

    if (state.activeTab === "declined") {
      dispatch({ type: 'onResetTalentTab', payload: { tab: "accepted" } });
    }

    if (state.activeTab === "skipped") {
      dispatch({ type: 'onResetTalentTab', payload: { tab: "accepted" } });
      dispatch({ type: 'onResetTalentTab', payload: { tab: "declined" } });
    }

  };

  const getDateFormattedString = (date: string) => {
    //This is the date format that comes from the API
    const formattedDate = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
    //This is the current date with the proper format to get the difference
    const dateToCompare = moment.utc().format('YYYY-MM-DD HH:mm:ss');
    //The following 2 variables are just using the date value of the formatted date
    //to check if we are in the same day or not
    const initDate = moment.utc(date).startOf('day');
    const currentDate: any = moment.utc().startOf('day');
    const isSameDay = initDate.isSame(currentDate);

    if (isSameDay) {
      //If we are in the same day, we just need to display how long ago
      //the role was last reviewed.
      const minutes = Math.abs(moment(formattedDate).diff(dateToCompare, 'minute'));
      const hours = Math.abs(moment(formattedDate).diff(dateToCompare, 'hour'));

      if (minutes > 60 && hours > 0 && hours < 24) {
        return `${hours} h ago`;
      }

      return `${minutes} min ago`;
    }

    //If we are not in the same day, we just display the date in the UI.
    return moment.utc(date).local().format('MMMM Do, YYYY');
  };

  const handleAdjustSearchCriteriaComplete = () => {
    dispatch({ type: 'onAdjustSearchCriteriaCompleted' });
  }



  const handleOpenSearchCriteriaFromFeedback = (trigger: boolean | null = null) => {
    console.log('shouldTriggerFeedbackFlow in modal', shouldTriggerFeedbackFlow);
    if (role) {
      roleSetup({
        role,
        roleId,
        roleName: role.name,
        initialStep: role.sourcing_only ? 3 : 5,
        onAdjustSearchCriteriaComplete: handleAdjustSearchCriteriaComplete,
        shouldTriggerFeedbackFlow: trigger || shouldTriggerFeedbackFlow,
        ...(state.showRejectionScreen === true ? { onAdjustSearchCriteriaComplete: handleAdjustSearchCriteriaComplete } : {})
      });
      appDispatch(toggleRoleMenu(false));
    }
  }

  const handleOpenSearchCriteria = () => {
    // This prevents search criteria from getting opened while role is not loaded yet
    // this is applicable for when someone tries to open the search criteria at the speed of light
    // after page loads
    if (role) {
      roleSetup({
        role,
        roleId,
        roleName: role.name,
        initialStep: role.sourcing_only ? 3 : 5,
        ...(state.showRejectionScreen === true ? { onAdjustSearchCriteriaComplete: handleAdjustSearchCriteriaComplete } : {})
      });
      appDispatch(toggleRoleMenu(false));
    }
  };

  const handleAdjustSearchClick = () => {
    console.log('handleAdjustSearchClick', shouldTriggerFeedbackFlow);
    if (extraRoleData?.customer?.is_active) {
      handleOpenSearchCriteriaFromFeedback();
    } else {
      inactivePlanModal({});
    }
  }

  return (
    <>
      <div style={{
        position: 'sticky',
        top: 0
      }}>
        {extraRoleData && state.roleStats && state.reviewTalentCount === 0 && !role?.completed && !role?.on_hold && !isRoleInFeedbackLoop ? <MoreCandidatesComingUp /> : null}
      </div>
      <Container size={'xl'} className={classes.container}>
        <TalentDrawer
          role={role!}
          handleSetTalent={handleSelectTalent}
          handlePatchCompleted={handlePatchCompleted}
          isButtonsActive={state.areButtonsActive}
          isFeatureLocked={!extraRoleData?.customer?.is_active}
        />

          <Stack mb={20} mt={5} spacing={10} >
            <RolebotButton
            type={'link'}
              onClick={() => navigate(-1)}
              className={classes.backToAllRolesButton}
            >
              <FontAwesomeIcon icon={faChevronLeft} style={{ paddingRight: '6px' }} /> Back to All Roles
            </RolebotButton>
            <Group className={classes.roleHeader} position={'apart'} noWrap>
              <Group align={'center'} noWrap spacing={0}>
                <Tooltip label={role?.name}>
                  <Text
                    role="title"
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: 28,
                      color: '#242424',
                      wordBreak: 'break-all',
                      overflow: 'hidden',
                      fontWeight: 500
                    }}
                    lineClamp={1}
                  >
                    {isError ? (error as any)?.data?.message : role?.name || 'Loading...'}
                  </Text>
                </Tooltip>
                {role?.on_hold && (
                  <Badge variant={'filled'} ml={10}>
                    On Hold
                  </Badge>
                )}
                {role?.completed && (
                  <Badge variant={'filled'} ml={10}>
                    Completed
                  </Badge>
                )}
                {role?.role_requests?.find((x) => x.type === 'open') && (
                  <Badge variant={'filled'} ml={10}>
                    Reactivation Pending
                  </Badge>
                )}
              </Group>
              <Group className={classes.roleHeaderOptions} noWrap>
                {state.lastReviewedDate && (
                  <>
                    <FontAwesomeIcon icon={faClockFour} style={{ color: '#838485', width: '13px', paddingRight: '1px' }} />
                    <Text
                      role="title"
                      sx={{
                        color: '#838485',
                        fontWeight: 'normal',
                        fontSize: 15,
                      }}
                    >
                      Last reviewed {getDateFormattedString(state.lastReviewedDate)}
                    </Text>
                    {state.lastReviewedByUsername && state.lastReviewedByEmail && (
                      <Tooltip label={`${state.lastReviewedByEmail}${state.isLastReviewedByInactive ? ' (inactive)' : ''}`}>
                        <Avatar classNames={{ root: classes.avatar, placeholder: classes.placeholder }}>
                          <Title order={2}>{getNameInitials(state.lastReviewedByUsername).toUpperCase()}</Title>
                        </Avatar>
                      </Tooltip>
                    )}
                  </>
                )}
                {role && extraRoleData?.customer?.is_active ?
                  <Group noWrap className={classes.button} onClick={() => extraRoleData?.customer?.is_active ? handleOpenSearchCriteriaFromFeedback() : inactivePlanModal({})}>
                    <Text color={'#838485'} sx={{ display: 'block', whiteSpace: 'nowrap' }}>
                      Search Criteria
                    </Text>
                    {(state.skillsSuggestionsCount > 0 || state.titlesSuggestionsCount > 0) && (
                      <Box sx={{ width: 12, height: 12, borderRadius: '50%', backgroundColor: '#D6328AD9' }} />
                    )}
                  </Group>
                  : null}
                {role && extraRoleData?.customer?.is_active ?
                  <RoleMenu role={role} roleId={roleId || ''} roleName={role?.name || ''} />
                  : null
                }
              </Group>
            </Group>
          </Stack>

        <div style={{ margin: '0 ', height: '100%', overflowX: 'hidden' }}>
          <RolebotTabs
            sx={(theme) => ({
              marginTop: 10,
              [theme.fn.largerThan('md')]: {
                marginTop: 0,
              },
            })}
            styles={{
              tabsListWrapper: {
                margin: '0 -32px',
              },
            }}
            initialTab={state.activeTabIndex}
            active={state.activeTabIndex}
            onTabChange={(tabIndex, tabKey) => {
              handleTabChange(tabIndex, tabKey);
            }}
          >
            {(state.showTalentToReviewScreen === true ||
              state.showFeedbackLoadingScreen === true ||
              state.showNoTalentFoundScreen === true ||
              state.showRejectionScreen === true ||
              state.showRolebotDancingScreen === true ||
              (state.showRoleAllSetScreen === true && state.totalTalentNumber === 0)
            ) ? (
              <Tabs.Tab label={<TabLabel text={'Candidates to Review'} amount={state.reviewTalentCount} />} tabKey="review">

                {/* If we have talent to review, just display the list*/}
                {state.showTalentToReviewScreen === true ? (
                  <Box px={10} sx={{ flex: 1 }}>
                    <TalentStack
                      data={state.review}
                      roleId={roleId!}
                      type={'review'}
                      handleSetTalent={handleSelectTalent}
                      isButtonsActive={state.areButtonsActive}
                      onEndReached={handleGetMoreTalent}
                      isFetching={state.isFetching}
                      onPatchCompleted={handlePatchCompleted}
                      page={getPageNumber()}
                      isSourceOnly={role?.sourcing_only}
                      extraRoleData={extraRoleData}
                    />
                  </Box>
                ) : <></>}

                {/* If we don't have talent to review, and we are waiting for the agents to get back with search samples */}
                {/* we show the loading screen */}
                {state.showFeedbackLoadingScreen === true && (
                  <FeedbackLoadingScreen />
                )}

                {/* If we don't have talent to review, and the agents already tried to find search samples */}
                {/* we show the no talent found screen */}
                {state.showNoTalentFoundScreen === true && (
                  <FeedbackTalentNotFound onAdjustSearchClick={handleAdjustSearchClick} />
                )}

                {/* If we don't have any talent and the response from the backend set the show rejection feedback as true */}
                {/* we show the feedback rejection screen. This is updated in the handlePatchCompleted method. */}
                {state.showRejectionScreen === true && (
                  <FeedbackRejectionScreen onAdjustSearchClick={handleAdjustSearchClick} />
                )}

                {/* show rolebot dancing happy */}
                {state.showRolebotDancingScreen === true && (
                  <CandidateToReviewSuccess />
                )}

                {state.showRoleAllSetScreen === true && (
                  <RoleAllSetScreen />
                )}

              </Tabs.Tab>
            ) : <></>}

            {state.acceptedTalentCount > 0 ? (
              <Tabs.Tab label={<TabLabel text={'Accepted'} amount={state.acceptedTalentCount} />} tabKey="accepted">
                <Box px={10} sx={{ flex: 1 }}>
                  <TalentStack
                    data={state.accepted}
                    roleId={roleId!}
                    type={'accepted'}
                    handleSetTalent={handleSelectTalent}
                    isButtonsActive={state.areButtonsActive}
                    onEndReached={handleGetMoreTalent}
                    isFetching={state.isFetching}
                    onPatchCompleted={handlePatchCompleted}
                    page={getPageNumber()}
                    isSourceOnly={role?.sourcing_only}
                    extraRoleData={extraRoleData}
                  />
                </Box>
              </Tabs.Tab>
            ) : <></>}

            {state.declinedTalentCount > 0 ? (
              <Tabs.Tab label={<TabLabel text={'Declined'} amount={state.declinedTalentCount} />} tabKey="declined">
                <Box px={10} sx={{ flex: 1 }}>
                  <TalentStack
                    data={state.declined}
                    roleId={roleId!}
                    type={'declined'}
                    handleSetTalent={handleSelectTalent}
                    isButtonsActive={state.areButtonsActive}
                    onEndReached={handleGetMoreTalent}
                    isFetching={state.isFetching}
                    onPatchCompleted={handlePatchCompleted}
                    page={getPageNumber()}
                    isSourceOnly={role?.sourcing_only}
                    extraRoleData={extraRoleData}
                  />
                </Box>
              </Tabs.Tab>
            ) : <></>}

            {state.skippedTalentCount > 0 ? (
              <Tabs.Tab label={<TabLabel text={'Skipped'} amount={state.skippedTalentCount} />} tabKey="skipped">
                <Box px={10} sx={{ flex: 1 }}>
                  <TalentStack
                    data={state.skipped}
                    roleId={roleId!}
                    type={'skipped'}
                    handleSetTalent={handleSelectTalent}
                    isButtonsActive={state.areButtonsActive}
                    onEndReached={handleGetMoreTalent}
                    isFetching={state.isFetching}
                    onPatchCompleted={handlePatchCompleted}
                    page={getPageNumber()}
                    isSourceOnly={role?.sourcing_only}
                    extraRoleData={extraRoleData}
                  />
                </Box>
              </Tabs.Tab>
            ) : <></>}

            {state.unavailableTalentCount > 0 ? (
              <Tabs.Tab label={<TabLabel text={'Unavailable'} amount={state.unavailableTalentCount} />} tabKey="unavailable">
                <Box px={10} sx={{ flex: 1 }}>
                  <TalentStack
                    data={state.unavailable}
                    roleId={roleId!}
                    type={'unavailable'}
                    handleSetTalent={handleSelectTalent}
                    isButtonsActive={state.areButtonsActive}
                    onEndReached={handleGetMoreTalent}
                    isFetching={state.isFetching}
                    onPatchCompleted={handlePatchCompleted}
                    page={getPageNumber()}
                    isSourceOnly={role?.sourcing_only}
                    extraRoleData={extraRoleData}
                  />
                </Box>
              </Tabs.Tab>
            ) : <></>}

            {state.interviewTalentCount > 0 ? (
              <Tabs.Tab label={<TabLabel text={'Interview'} amount={state.interviewTalentCount} />} tabKey="interview">
                <Box px={10} sx={{ flex: 1 }}>
                  <TalentStack
                    data={state.interview}
                    roleId={roleId!}
                    type={'interview'}
                    handleSetTalent={handleSelectTalent}
                    isButtonsActive={state.areButtonsActive}
                    onEndReached={handleGetMoreTalent}
                    isFetching={state.isFetching}
                    onPatchCompleted={handlePatchCompleted}
                    page={getPageNumber()}
                    isSourceOnly={role?.sourcing_only}
                    extraRoleData={extraRoleData}
                  />
                </Box>
              </Tabs.Tab>
            ) : <></>}

          </RolebotTabs>
        </div>
      </Container>
    </>
  );
};

const TabLabel = ({ text, amount }: { text: string; amount: number }) => {
  return (
    <Group noWrap spacing={10}>
      <Text sx={{ fontFamily: 'Roboto', fontSize: 19, fontWeight: 500 }}>{text} </Text>
      <Text component={'span'} sx={{ fontFamily: 'Roboto', fontSize: 19 }}>
        {amount}
      </Text>
    </Group>
  );
};

export default Role;
