import { X } from 'tabler-icons-react';
import { Sx, TextInput, createStyles } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';

interface SearchInputProps {
  value: string;
  onChange: (arg: string) => void;
  width?: number;
  sx?: Sx;
  customPlaceholder?: string;
  className?: string;
}

const useStyles = createStyles((theme) => ({
  searchIcon: {
    height: '16px',
    paddingBottom: '2px'
  },
  closeIcon: {
    cursor: 'pointer',
    marginRight: '10px',
    color: '#4F4F4F'
  },
  input: {
    transition:
      'background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease',
    borderRadius: 40,
    '&:hover': {
      boxShadow: '0 0 0 0px rgba(0, 0, 0, 0.1)',
    },
    '&:focus': {
      boxShadow: ' 0px 0px 15px rgba(97, 81, 215, 0.1), inset 0px 0px 4px rgba(0, 0, 0, 0.25)',
    },
  }
}));

const SearchInput = ({ value, onChange, sx, customPlaceholder, className }: SearchInputProps) => {
  const { classes, cx } = useStyles();

  const rightSection = value ? (
    <X size={20} onClick={() => onChange('')} className={classes.closeIcon} />
  ) : null;

  return (
    <TextInput
      className={cx(classes.input, className)}
      placeholder={customPlaceholder ? customPlaceholder : 'Search'}
      variant={'default'}
      radius="xl"
      size="md"
      icon={<FontAwesomeIcon icon={faMagnifyingGlass} className={classes.searchIcon} />}
      rightSection={rightSection}
      sx={sx}
      value={value}
      onChange={({ target }) => onChange(target.value)}
    />
  );
};

export default SearchInput;
