import { Center, createStyles } from "@mantine/core";
import { useGetUserQuery } from "app/services/rolebot";
import { getNameInitials } from 'utils';

const useStyles = createStyles((theme) => ({
    avatar: {
      fontSize: theme.fontSizes.xl,
      color: theme.colors.gray[7],
      fontWeight: 500,
      fontFamily: 'Roboto',
      borderRadius: '50%',
      width: 47,
      height: 47,
      backgroundColor: theme.colors.gray[2],
    }
}))

const UserAvatar = () => {
    const {classes} = useStyles()
    const user = useGetUserQuery();
    const userName = user?.data?.user?.name || '';

    return  (
    <Center className={classes.avatar}>
      {getNameInitials(userName)}
    </Center>
  )
}

export default UserAvatar;