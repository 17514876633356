import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { createStyles, Group, Text } from "@mantine/core";

import { NavLink } from "react-router-dom";

interface NavbarLinkProps {
    icon: any;
    label: string;
    active?: boolean;
    to: string;
    onClick?(): void;
  }

  const useStyles = createStyles((theme) => ({
    navLink: {
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        
        '&.active .navIconContainer': {
          backgroundColor: '#7039ED',
          boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.45)',
          ':hover': {
            backgroundColor: '#7039ED',
          },
          'svg': {
            color: '#FFF',
          },
        },
      },
    
      navIconContainer: {
        backgroundColor: '#261755',
        width: 47,
        height: 47,
        borderRadius: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        transition: '0.15s',
        
        ':hover': {
          backgroundColor: '#7039ED66',
        },
        'svg': {
          color: '#DFE1E1',
          ':hover': {
            color: '#FFF',
          },
        },
      },

      linkText: {
        color: '#000',
        fontSize: 30,
        textTransform: 'capitalize',
        fontFamily: 'Roboto',
        '.active &': {
            color: '#7039ED',
        },
        ':hover': {
          textDecoration: 'underline',
        },
        [theme.fn.largerThan('md')]: {
          display: 'none',
        },
      },

      navIcon: {
        height: '24px',
    
      }
  }));
  
  function NavbarLink({ icon, onClick, to, label }: NavbarLinkProps) {
    const { classes, cx } = useStyles();
  
    return (
      <NavLink onClick={onClick} className={({ isActive }) => cx(classes.navLink, { active: isActive })} to={to}>
        <Group className={cx('navIconContainer', classes.navIconContainer)}>
          <FontAwesomeIcon icon={icon} className={classes.navIcon} />
        </Group>
        <Text className={classes.linkText}>{label}</Text>
      </NavLink>
    );
  }

  export default NavbarLink;